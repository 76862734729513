import {mapActions, mapGetters} from "vuex";

export default {
    computed:{
        ...mapGetters({
            order:'orders/order',
            options:'config/options',
        })
    },
    created() {
        this.getOptions();
    },
    methods:{
        closePopup(){
            this.$emit('closePopup');
        },
        ...mapActions({
            getOptions: 'config/getOptions',
        })
    }
}